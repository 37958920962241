
// ** Initial State
const initialState = {
    playTour: JSON.parse(localStorage.getItem("playTour"))
   }
   
   const playTourReducer = (state = initialState, action) => {
     switch (action.type) {
       case 'SET_TOUR':
         return { ...state, playTour: action.playTour}
       default:
         return state
     }
   }
   
   export default playTourReducer
   