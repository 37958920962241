
// ** Initial State
const initialState = {
 skin: JSON.parse(localStorage.getItem("skin"))
}

const appThemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_THEME':
      return { ...state, skin: action.theme}
    default:
      return state
  }
}

export default appThemeReducer
